import * as anchor from "@project-serum/anchor";

export const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

export const config = new anchor.web3.PublicKey(
  "334QiLP96RbmRwDSXfiqvdhntgEU3YTdu8LDumxRidDc"
);
export const configUUID = "334QiL";

export const candyMachineOwner = new anchor.web3.PublicKey(
  "AUaeHCdYuj7x8EbNrd9gDrZ6vWbKNbrXpnzwzugeZG53"
);
