import React from "react";
import "./App.css";
import { WalletProvider, useWallet } from "./providers/useWallet";
import { mintNFT } from "./actions/mintNFT";
import { CandyMachineProvider, useCandyMachine } from "./providers/useCandyMachine";

function App() {
  return (
    <div className="App">
      <WalletProvider>
        <CandyMachineProvider>
          <BuyScreen />
        </CandyMachineProvider>
      </WalletProvider>
    </div>
  );
}

function BuyScreen() {
  const { connection, wallet, connectWallet } = useWallet();
  const { candyMachine, findCandyMachine } = useCandyMachine();
  let displayRemaining = "";
  if (connection && wallet && !candyMachine) {
    findCandyMachine(connection, wallet);
    console.log(candyMachine);
  }
  if (candyMachine) {
    displayRemaining =
      (702 - candyMachine.itemsRedeemed.toNumber()).toString() +
      "/702 remaining";
  }

  return (
    <header className="App-header">
      <div className="Container">
        <h1>Lootters</h1>
        <p>
          Lootters is a collection of letters in the Loot Project font EB
          Garamond. <br></br>
          To start off all combinations of 1 and 2 letters are released.
          <br></br>
          <br></br>
          You may mint a random Lootter for ◎2. {displayRemaining} <br></br>
          Feel free to use Lootters in any way you want.
        </p>

        <div className="grid2x2">
          {(!wallet || !wallet.connected) && (
            <>
              <div
                onClick={() => {
                  connectWallet("sollet");
                }}
              >
                Sollet
              </div>
              <div
                className="phantom"
                onClick={() => {
                  connectWallet("phantom");
                }}
              >
                Phantom
              </div>
            </>
          )}
          {wallet && wallet.connected && (
            <>
              <div
                onClick={() => {
                  mintNFT(connection, wallet);
                }}
              >
                Mint 1
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
export default App;
